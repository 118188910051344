import moment from 'moment';
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from 'react-router-dom';
import { darkTheme } from '@stitchesConfig';
import { RootLayout } from '@views/Layout/RootLayout';
import { Login } from '@views/Login';
import { Registration } from '@views/Registration';
import { ForgottenPassword } from '@views/ForgottenPassword';
import { ResetPassword } from '@views/ResetPassword';
import { Profile } from '@views/Profile';
import { Noticeboard } from '@views/Noticeboard';
import { Shipments } from '@views/Shipments';
import { useEffect } from 'react';
import { useDarkMode, useReadLocalStorage } from 'usehooks-ts';
import { RequireAuth } from '@views/RequireAuth';
import { ProductMaster } from '@views/ProductMaster';
import { StockBySKU } from '@views/StockBySKU';
import { ConfirmEmail } from '@views/ConfirmEmail';
import { Team } from '@views/Team';
import { Join } from '@views/Join';
import { PublicPage } from '@views/PublicPage';
import { WarehouseSearch } from '@views/WarehouseSearch';
import { Questionnaire } from '@components/Questionnaire';
import { WarehouseMaster } from '@views/WarehouseMaster';
import { WarehouseDetails } from '@views/WarehouseDetails';
import { FleetMaster } from '@views/FleetMaster';
import { PriceMaster } from '@views/PriceMaster';
import { FleetAvailability } from '@views/FleetAvailability';
import { CustomerMaster } from '@views/CustomerMaster';
import { SupplierMaster } from '@views/SupplierMaster';
import { WarehouseShortlist } from '@views/WarehouseShortlist';
import { CustomerShortlist } from '@views/CustomerShortlist';
import { LegalDocumentsHub } from '@views/LegalDocumentHub';
import { BusinessProfile } from '@views/Profile/Profile';
import { UpdateFinancialInformation } from '@views/UpdateFinancialInformation';
import { useSetAtom } from 'jotai';
import {
  BUSINESS_PROFILE_GET,
  LSP_QUESTION_SET,
  SHIPPER_QUESTION_SET,
} from './constants';
import { businessProfileAtom } from './store/jotai';

function App() {
  const { isDarkMode } = useDarkMode(false);
  const setBusinessProfile = useSetAtom(businessProfileAtom);
  // const authHttp = useAuthHttp();

  // useEffect(() => {
  //   if (isDarkMode) {
  //     document.body.className = darkTheme;
  //   } else {
  //     document.body.removeAttribute('class');
  //   }
  // }, [isDarkMode]);

  const isAuthenticated = useReadLocalStorage('isAuthenticated');
  const onFinish = (
    answers: Record<string, string | boolean | string[] | Date>
  ) => {
    const queryParams = Object.entries(answers)
      .map(([key, value]) => {
        if (key === 'intendedStartDate') {
          return `${key}=${encodeURIComponent(
            moment(value as Date).format('DD/MM/YYYY')
          )}`;
        }
        if (Array.isArray(value)) {
          return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
        }
        return `${key}=${encodeURIComponent(value as string | number)}`;
      })
      .join('&');

    const urlString = `http://localhost:3000/register?${queryParams}&role=logistics-provider&redirect=/warehouse-master?createWarehouse=true`;
    // const urlString = `http://localhost:3000/warehouse-search?${queryParams}&role=logistics-client`;

    window.open(urlString, '_blank')?.focus();
  };

  useEffect(() => {
    async function getBusinessProfile() {
      try {
        const res = await fetch(`/api${BUSINESS_PROFILE_GET}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',
          },
        });

        const data: BusinessProfile = await res.json();

        if (data) {
          setBusinessProfile(data.businessProfile);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error: ', error);
      }
    }
    getBusinessProfile();
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/' element={<RootLayout />}>
        {/* REGISTRATION ROUTES */}
        <Route
          path='/'
          element={
            <Navigate to={isAuthenticated ? '/noticeboard' : '/login'} />
          }
        />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Registration />} />
        <Route path='/forgotten-password' element={<ForgottenPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/confirm-email' element={<ConfirmEmail />} />
        <Route path='/join' element={<Join />} />
        {/* END REGISTRATION ROUTES */}

        {/* PUBLIC PAGES */}
        <Route element={<PublicPage />}>
          <Route path='/warehouse-search' element={<WarehouseSearch />} />
          <Route path='/warehouse/:id' element={<WarehouseDetails />} />
        </Route>

        {/* PROTECTED ROUTES */}
        <Route element={<RequireAuth />}>
          <Route path='/noticeboard' element={<Noticeboard />} />
          <Route path='/shipments' element={<Shipments />} />
          <Route path='/stock' element={<StockBySKU />} />
          <Route path='/product-sku-master' element={<ProductMaster />} />
          <Route path='/profile' element={<Profile />} />
          <Route
            path='/profile/update-financial-information'
            element={<UpdateFinancialInformation />}
          />
          <Route path='/team' element={<Team />} />
          <Route
            path='/embed'
            element={
              <Questionnaire
                questions={LSP_QUESTION_SET}
                onFinish={(answers) => onFinish(answers)}
                questionSet='lsp'
              />
            }
          />
          <Route path='/warehouse-master' element={<WarehouseMaster />} />
          <Route path='/fleet-master' element={<FleetMaster />} />
          <Route path='/transport-price-master' element={<PriceMaster />} />
          <Route path='/customer-master' element={<CustomerMaster />} />
          <Route path='/supplier-master' element={<SupplierMaster />} />
          <Route path='/fleet-availability' element={<FleetAvailability />} />
          <Route path='/warehouse-shortlist' element={<WarehouseShortlist />} />
          <Route path='/customers' element={<CustomerShortlist />} />
          <Route path='/legal-documents' element={<LegalDocumentsHub />} />
        </Route>
        {/* END PROTECTED ROUTES */}
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
